@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.myclass {
  text-shadow: 10px 10px 0 black, -10px -10px 0 black, 10px -10px 0 black,
    -10px 10px black;
}

.myclass_small {
  text-shadow: 4px 4px 0 black, -4px -4px 0 black, 4px -4px 0 black,
    -4px 4px black;
}
